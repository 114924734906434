<template>
  <SettingsOverlayStyled>
    <TabbedPanel :tabs="tabs" :title="$tc('setting', 2) | capitalize" @activateTab="activateTab">
      <template v-slot>
        <transition name="fade">
          <LanguageSelector v-if="activeTabID === 'language'" />
          <DateSettings v-if="activeTabID === 'date'" />
          <TimeSettings v-if="activeTabID === 'time'" />
          <UnitsSettings v-if="activeTabID === 'unit'" />
          <DefaultAppSettings v-if="activeTabID === 'app'" />
        </transition>
      </template>
    </TabbedPanel>
  </SettingsOverlayStyled>
</template>

<script>
import { styled } from '@egoist/vue-emotion'

import { TabbedPanel, LanguageSelector, DateSettings, TimeSettings, UnitsSettings, DefaultAppSettings } from '@common/components'
import { get } from 'lodash'

const SettingsOverlayStyled = styled('div')`
  .fade-leave-active {
    position: absolute;
  }
`

export default {
  inject: ['permissions'],
  components: {
    SettingsOverlayStyled,
    TabbedPanel,
    LanguageSelector,
    DateSettings,
    TimeSettings,
    UnitsSettings,
    DefaultAppSettings,
  },
  data() {
    return {
      activeTabID: 'language',
    }
  },
  computed: {
    tabs() {
      let tabs = [
        {
          id: 'language',
          name: this.$tc('language', 1),
        },
        {
          id: 'unit',
          name: this.$tc('unit', 2),
        },
        {
          id: 'date',
          name: this.$tc('date', 1),
        },
      ]
      if (this.showDefaultApp) {
        tabs.push({
          id: 'app',
          name: this.$tc('defaultApp', 1),
        })
      }
      return tabs.map(tab => {
        return {
          ...tab,
          isActive: this.activeTabID === tab.id,
        }
      })
    },
    showDefaultApp() {
      const allPermissions = get(this.permissions, 'all', []).map(({ name }) => name)
      const permissions = ['has_service_dealer_app', 'has_maintenance_app_v2']
      return permissions.every(permission => allPermissions.includes(permission))
    },
  },
  methods: {
    activateTab(tabID) {
      this.activeTabID = tabID
    },
  },
}
</script>
